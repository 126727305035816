import { useEffect, useState } from 'react';

import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import StatsTable from './StatsTable.js';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import ProductSearch from './ProductAdd.js';
import { Stack, Typography } from '@mui/material';
import StockedProducts from './StockedProducts.js';

const RegionTabs = ({
    isStoreOrderable, 
    isStyleOrderable, 
    inventoryData, 
    expectedRestockingSales,
    restockingTime,
    restockingSales,
    requiredQties, 
    expectedProductionSales, 
    productionTime,
    productionSales,
    usingLastPeriods,
    orderQties,
    expectedLastPeriodsSales,
    lastPeriodsSales,
    customRequired,
    lastYearGrowthRate,
    sizeRatios,
    onChangeRequired,
    onChangeStockedProducts,
}) => {
    const [tablesData, setTablesData] = useState();
    const [value, setValue] = useState('1');
    const stores = ["US", "CA", "EU"];

    useEffect(() => {
        if (!inventoryData || !requiredQties || !expectedProductionSales || !orderQties) return;
        const data = {};
        for (const regionCode in inventoryData.region) {
            data[regionCode] = [];
            for (const {sku, available, incoming, barcode, size} of inventoryData.region[regionCode]) {
                if (expectedRestockingSales[sku]?.[regionCode] === undefined || restockingSales[sku]?.[regionCode] === undefined
                    || expectedProductionSales[sku]?.[regionCode] === undefined || productionSales[sku]?.[regionCode] === undefined
                    || requiredQties[sku]?.[regionCode] === undefined) {
                        continue;
                }
                const order = orderQties[sku] ? orderQties[sku][regionCode] || 0 : 0;
                data[regionCode].push({
                    expectedRestockingSales: expectedRestockingSales[sku][regionCode],
                    available,
                    incoming,
                    barcode,
                    size,
                    sizeRatio: sizeRatios[size],
                    restockingSales: restockingSales[sku][regionCode],
                    usingLastPeriods: usingLastPeriods[sku]?.[regionCode],
                    productionSales: productionSales[sku][regionCode],
                    expectedProductionSales: expectedProductionSales[sku][regionCode],
                    expected: available + incoming - expectedProductionSales[sku][regionCode] - expectedRestockingSales[sku][regionCode],
                    required: requiredQties[sku][regionCode],
                    order,
                    sku,
                    lastPeriodsSales: lastPeriodsSales[sku][regionCode],
                    expectedLastPeriodsSales: expectedLastPeriodsSales[sku][regionCode],
                    styleOrderable: isStyleOrderable[getStyleFromSKU(sku)],
                });
            }
        }
        setTablesData(data);
    }, [inventoryData, requiredQties, expectedProductionSales, orderQties]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const getStyleFromSKU = (sku) => {
        return sku.replace(/-\d+$/, '');
    }

    const tabs = [];
    const panels = [];
    let currValue = 1;
    for (const store of stores) {
        tabs.push(<Tab key={store} label={<Badge invisible={!(isStoreOrderable && isStoreOrderable[store])} variant='dot' color='success'>{store.toUpperCase()}</Badge>} value={currValue.toString()} />);
        panels.push(<TabPanel key={store} value={currValue.toString()} sx={{padding:0}}>
            <Stack py={2}>
                <StockedProducts regionCode={store} />
                <Typography variant="h6" pt={2} component="h3">Table de commande</Typography>
                {!tablesData ? "Loading" : <StatsTable tableData={tablesData[store]} productionTime={productionTime} restockingTime={restockingTime} lastYearGrowthRate={lastYearGrowthRate} customRequired={customRequired[store]} onChangeRequired={(newStoreState) => onChangeRequired(store, newStoreState)} />}
            </Stack>
        </TabPanel>)
        currValue++;
    }

    return (
        <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', pl: 0, pr: 0 }}>
                <TabList onChange={handleChange} aria-label="store tabs">
                    {tabs}
                </TabList>
            </Box>
            {panels}
        </TabContext>
        </Box>
    )
}

export default RegionTabs;