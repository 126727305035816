import { useState, useEffect } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';

import { streamLineItems, streamClosedLineItems, streamOverReservedLineItems } from '../../firebase.js';

import { Grid, Typography, Button, Stack, MenuItem, ListItemIcon, Paper, Chip, ButtonGroup, Tooltip, Avatar, DialogContent, TextField, Select, InputLabel, Checkbox, Icon, Divider, colors } from '@mui/material';
import useAuth from '../../Hooks/useAuth.js';

import Box from '@mui/material/Box';
import Flag from "./Flag";
import FormControl from '@mui/material/FormControl';
import Menu from '@mui/material/Menu';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import ListItemText from '@mui/material/ListItemText';
import OutlinedInput from '@mui/material/OutlinedInput';
import { regionFromCountryCode } from "../../utils/regionFromCountryCode";
import SellIcon from '@mui/icons-material/Sell';
import CustomerDataMenuItem from './CustomerDataMenuItem';
import CreateOrderMenuItem from './CreateOrderMenuItem.js';
import DownloadLabelsDialog from './DownloadLabelsDialog.js';
import IconButton from '@mui/material/IconButton';
import LineItem from './LineItem.js';
import LogDialog from './logDialog.js';
import { MoreHoriz } from '@mui/icons-material';

function BrazilOrders({ setTitle, setLoading, setMenuItems }) {
  const [showOld, setShowOld] = useState(false);
  const [openLineItemsSnapshot, setOpenLineItemsSnapshot] = useState();
  const [closedLineItemsSnapshot, setClosedLineItemsSnapshot] = useState();
  const [overReservedLineItemsSnapshot, setOverReservedLineItemsSnapshot] = useState();
  const [lineItems, setLineItems] = useState([]);
  const [filteredLineItems, setFilteredLineItems] = useState([]);
  const [inputText, setInputText] = useState('');
  const [searchText, setSearchText] = useState('');
  const [tagFilters, setTagFilters] = useState(['New', 'Reserved', 'Unavailable', 'Sent']);
  const [regionFilters, setRegionFilters] = useState(['CANADA', 'GERMANY', 'USA']);
  const [sortOption, setSortOption] = useState('default');
  const [loadingCustomerData, setLoadingCustomerData] = useState(false);
  const [actionAnchorEl, setActionAnchorEl] = useState(null);
  const [selectionAnchorEl, setSelectionAnchorEl] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [downloadLabelsDialogVisible, setDownloadLabelsDialogVisible] = useState(false);
  const [logDialog, setLogDialog] = useState(null);
  const { user } = useAuth();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  /**
   * Delays the searchInput text
   */
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSearchText(inputText); // Update searchText after 1 second
    }, 500);

    return () => clearTimeout(delayDebounceFn); // Cleanup the timeout on input change or unmount
  }, [inputText]);

  useEffect(() => {
    const menuItems = <Stack direction={'row'} spacing={1}>
      <TextField label={'Search'} size='small' onChange={handleSearchTextChange} value={inputText} />
      <FormControl sx={{ minWidth: 132 }}>
        <InputLabel size='small' id="status-checkbox-label">Tag Filter</InputLabel>
        <Select
          size='small'
          labelId="status-checkbox-label"
          id="status-checkbox"
          multiple
          value={tagFilters}
          onChange={handleTagFilterChange}
          input={<OutlinedInput size='small' label="Tag Filter" />}
          renderValue={(selected) => (
            <Stack direction={'row'} display={'inline-flex'} lineHeight={'inherit'} spacing={0.5} height={'100%'} alignItems={'center'} justifyContent={'center'}>
              {tags.map(tag => <Box key={tag} height={14} width={14} borderRadius={7} bgcolor={selected.includes(tag) ? getTagColor(tag) : 'gray'} />)}
            </Stack>
          )}
        >
          {tags.map((tag) => (
            <MenuItem key={tag} value={tag}>
              <Checkbox checked={tagFilters.includes(tag)} />
              <ListItemText primary={tag} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl sx={{ minWidth: 132 }}>
        <InputLabel size='small' id="region-filter-label">Region Filter</InputLabel>
        <Select
          size='small'
          labelId="region-filter-label"
          id="region-filter"
          multiple
          value={regionFilters}
          onChange={handleRegionFilterChange}
          input={<OutlinedInput size='small' label="Region Filter" />}
          renderValue={(selected) => (
            <Stack direction={'row'} display={'inline-flex'} lineHeight={'inherit'} spacing={0.5} height={'100%'} alignItems={'stretch'} justifyContent={'center'}>
              {regionFilters.sort((a, b) => {
                return a.localeCompare(b)
              }).map(region => <Flag key={region} overlay height="16" flag={region} />)}
            </Stack>
          )}
        >
          {regions.map((region) => (
            <MenuItem key={region} value={region}>
              <Checkbox checked={regionFilters.includes(region)} />
              <ListItemText primary={region} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button
        aria-label="selection-menu"
        aria-controls={selectionMenuId}
        aria-haspopup="true"
        onClick={handleSelectionMenuOpen}
        color="inherit"
      >
        Select
      </Button>
      <IconButton
        aria-label="action-menu"
        aria-controls={actionMenuId}
        aria-haspopup="true"
        onClick={handleActionMenuOpen}
        color="inherit"
      >
        <MoreHoriz />
      </IconButton>
    </Stack>
    setMenuItems(menuItems);
  }, [setMenuItems, inputText, tagFilters, regionFilters]);

  const handleToggleShowOld = () => {
    setShowOld(!showOld);
  }

  const handleActionMenuClose = () => {
    setActionAnchorEl(null);
  }

  const isActionMenuOpen = Boolean(actionAnchorEl);

  const handleActionMenuOpen = (event) => {
    setActionAnchorEl(event.currentTarget);
  }

  const handleSelectionMenuClose = () => {
    setSelectionAnchorEl(null);
  }

  const isSelectionMenuOpen = Boolean(selectionAnchorEl);

  const handleSelectionMenuOpen = (event) => {
    setSelectionAnchorEl(event.currentTarget);
  }

  const handleSetLoadingCustomerData = (loading) => {
    setLoadingCustomerData(loading);
  }

  const handleSelectItems = (lineItems) => {
    handleSelectionMenuClose();
    setSelectedItems(lineItems);
  }

  const handleAddToSelection = (lineItem) => {
    setSelectedItems([...selectedItems, lineItem]);
  }

  const handleRemoveFromSelection = (lineItem) => {
    setSelectedItems(selectedItems.filter((item => item.id !== lineItem.id)));
  }

  const handleShowDownloadLabelsDialog = () => {
    setDownloadLabelsDialogVisible(true)
    setActionAnchorEl(null);
  }

  const handleCloseDownloadLabelsDialog = () => {
    setDownloadLabelsDialogVisible(false)
  }

  const handleOpenLogDialog = (lineItem) => {
    setLogDialog(lineItem);
  }

  const handleCloseLogDialog = () => {
    setLogDialog(null);
  }

  const handleSearchTextChange = (event) => {
    setInputText(event.currentTarget.value);
  }

  const handleTagFilterChange = (event) => {
    const {
      target: { value },
    } = event;
    setTagFilters(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const handleRegionFilterChange = (event) => {
    const {
      target: { value },
    } = event;
    setRegionFilters(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  useEffect(() => {
    setLoading(true)
    setTitle('STOCK');
    const unsubscribeOpen = streamLineItems(async (snapshot) => {
      setOpenLineItemsSnapshot(snapshot)
    });
    const unsubscribeClosed = streamClosedLineItems(async (snapshot) => {
      setClosedLineItemsSnapshot(snapshot)
    });
    const unsubscribeOverReserved = streamOverReservedLineItems(async (snapshot) => {
      setOverReservedLineItemsSnapshot(snapshot)
    });
    return () => {
      unsubscribeOpen();
      unsubscribeClosed();
      unsubscribeOverReserved();
    };
  }, []);

  useEffect(() => {
    // Consolidate openLineItems and closedLineItems
    if (!openLineItemsSnapshot || !closedLineItemsSnapshot || !overReservedLineItemsSnapshot) return;

    const docs = [...openLineItemsSnapshot.docs, ...closedLineItemsSnapshot.docs, ...overReservedLineItemsSnapshot.docs].map(doc => {
      const data = doc.data();
      const requiredQty = data.requiredQty || 0;
      const reservedQty = data.reservedQty || 0;
      const sentQty = data.sentQty || 0;
      const unavailableQty = data.unavailableQty || 0;
      const newQty = Math.max(requiredQty - reservedQty - sentQty - unavailableQty, 0);

      // convert old log to new log
      const docLog = data.log?.map(item => {
        if (!item.event) {
          // old log
          const text = item.text;
          if (!text) {
            return null;
          }
          return {
            ...item,
            event: {
              type: 'text',
              text
            }
          }
        } else {
          return item;
        }
      }) || [];
      const log = [...docLog, ...(data.notes?.map(n => ({
        event: {
          type: 'comment',
          comment: n.note,
        },
        user: n.user,
        timestamp: n.timestamp,
      })) || [])].sort((a, b) => a.timestamp - b.timestamp);

      const tags = [];
      if (newQty) {
        tags.push('New');
      }
      if (reservedQty) {
        tags.push('Reserved');
      }
      if (sentQty) {
        tags.push('Sent');
      }
      if (unavailableQty) {
        tags.push('Unavailable');
      }
      if (!requiredQty && !reservedQty) {
        tags.push('Archived');
      }

      return {
        id: doc.id,
        docId: doc.id,
        barcode: data.barcode,
        imgSrc: data.imgSrc,
        orderName: data.orderName,
        date: new Date(data.createdAt),
        orderId: data.orderId,
        shippingCountryCode: data.shippingCountryCode,
        sku: data.sku,
        requiredQty,
        reservedQty,
        overReserved: requiredQty < reservedQty + unavailableQty,
        sentQty,
        newQty,
        unavailableQty,
        unavailableDetail: data.unavailableDetail,
        log,
        status: tags,
        actions: !!newQty,
      };
    })
    setLineItems(docs);
  }, [openLineItemsSnapshot, closedLineItemsSnapshot, overReservedLineItemsSnapshot, showOld]);

  useEffect(() => {
    if (!lineItems) return;
    if (!lineItems.length) return;

    let nextLineItems = lineItems;

    if (searchText) {
      nextLineItems = nextLineItems.filter(doc => {
        for (const element of searchText.split(/[\s_\-&/]/)) {
          if (!doc.id.toLowerCase().includes(element.toLowerCase())) return false;
        }
        return true;
      });
    }

    if (tagFilters) {
      nextLineItems = nextLineItems.filter(doc => {
        if (!tagFilters.includes('Archived') && doc.status.includes("Archived")) {
          return false;          
        }
        return tagFilters.some(status => doc.status.includes(status));
      });
    }

    if (regionFilters) {
      nextLineItems = nextLineItems.filter(doc => {
        return regionFilters.some(region => regionFromCountryCode(doc.shippingCountryCode) === region);
      });
    }

    // Sort default or orderName
    if (sortOption === 'order-name') {
      nextLineItems.sort((a, b) => {
        return b.id - a.id;
      })
    } else {
      nextLineItems.sort((a, b) => {
        const tagPriority = {
          "New": 1,
          "Unavailable": 2,
          // "overReserved" will be treated separately
          "Reserved": 4,
          // "NO TAGS" will be treated separately
          "Sent": 6,
        };
        // Define a helper function to determine the priority of an item
        const getPriority = (item) => {
          if (item.overReserved) {
            return 3; // Place "overReserved" between "Unavailable" and "Reserved"
          } if (item.status.includes("Archived")) {
            return 7;
          } else if (item.status.length === 0) {
            return 5;
          }
          // Get the highest priority tag
          const highestPriorityTag = item.status
            .map(tag => tagPriority[tag])
            .sort((a, b) => a - b)[0];
          return highestPriorityTag;
        };

        const priorityA = getPriority(a);
        const priorityB = getPriority(b);

        // Compare by priority first
        if (priorityA < priorityB) {
          return -1;
        }
        if (priorityA > priorityB) {
          return 1;
        }

        // If tags are the same, compare by date (newest to oldest)
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        return dateB - dateA; // Sort from newest to oldest
      });
    }

    setFilteredLineItems(nextLineItems);
    setLoading(false);
  }, [lineItems, searchText, tagFilters, regionFilters, showOld, sortOption]);

  const newPairs = filteredLineItems.reduce((acc, cur) => acc + Math.max((cur.requiredQty || 0) - (cur.sentQty || 0) - (cur.reservedQty || 0) - (cur.unavailableQty || 0), 0), 0);
  const reservedPairs = filteredLineItems.reduce((acc, cur) => acc + (cur.reservedQty || 0), 0);
  const unavailablePairs = filteredLineItems.reduce((acc, cur) => acc + (cur.unavailableQty || 0), 0);
  const totalPairs = newPairs + reservedPairs;
  const tags = ['New', 'Reserved', 'Unavailable', 'Sent', 'Archived'];
  const regions = ['CANADA', 'GERMANY', 'USA'];

  const selectionMenuId = 'selection-menu';
  const renderSelectionMenu = (
    <Menu
      anchorEl={selectionAnchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      id={selectionMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isSelectionMenuOpen}
      onClose={handleSelectionMenuClose}
      disableScrollLock={true}
    >
      <MenuItem onClick={() => handleSelectItems(filteredLineItems)}>
        <ListItemIcon><LibraryAddCheckIcon /></ListItemIcon>
        <ListItemText>Select All</ListItemText>
      </MenuItem>
      <MenuItem onClick={() => handleSelectItems(filteredLineItems.filter((item) => item.status.includes('New')))}>
        <ListItemIcon sx={{color: 'primary.main'}}><LibraryAddCheckIcon /></ListItemIcon>
        <ListItemText>Select New</ListItemText>
      </MenuItem>
      <MenuItem onClick={() => handleSelectItems(filteredLineItems.filter((item) => item.status.includes('Reserved')))}>
        <ListItemIcon sx={{color: 'success.main'}}><LibraryAddCheckIcon /></ListItemIcon>
        <ListItemText>Select Reserved</ListItemText>
      </MenuItem>
      <MenuItem onClick={() => handleSelectItems([])}>
        <ListItemIcon></ListItemIcon>
        <ListItemText>Clear Selection</ListItemText>
      </MenuItem>
    </Menu>
  );

  const actionMenuId = 'action-menu';
  const renderActionMenu = (
    <Menu
      anchorEl={actionAnchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      id={actionMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isActionMenuOpen}
      onClose={handleActionMenuClose}
      disableScrollLock={true}
    >
      <MenuItem onClick={handleShowDownloadLabelsDialog}>
        <ListItemIcon><SellIcon /></ListItemIcon>
        <ListItemText>Download{selectedItems.length ? ' Selected' : ''} Labels...</ListItemText>
      </MenuItem>
      {user?.isAdmin && <CustomerDataMenuItem loading={loadingCustomerData} lineItems={lineItems} closeMenu={handleActionMenuClose} setLoadingCustomerData={handleSetLoadingCustomerData} />}
      <CreateOrderMenuItem lineItems={lineItems.filter(li => li.reservedQty > 0)} closeMenu={handleActionMenuClose} />
    </Menu>
  );

  const getTagColor = (tag) => {
    switch (tag) {
      case 'New':
        return 'primary.main';
      case 'Reserved':
        return 'success.main';
      case 'Unavailable':
        return 'warning.main';
      case 'Sent':
        return 'secondary.main';
      case 'Archived':
        return 'archived.main';
      default:
        return 'gray';
    }
  }

  return (
    <>
      <Grid container justifyContent="center" spacing={2}>
        <Grid item xs={12}>
          <Stack
            sx={{ maxWidth: '600px', margin: '14px auto' }}
            direction="row"
            spacing={2}>
            <Paper variant="outlined" sx={{ padding: '8px 12px', flexGrow: 1 }}>
              <Box>
                <Typography fontSize="11px" sx={{ borderBottom: 'dotted 2px', display: 'inline-block' }}>New Items</Typography>
              </Box>
              <Typography fontSize="18px" fontWeight="bold" color="primary">{newPairs}</Typography>
            </Paper>
            <Paper variant="outlined" sx={{ padding: '8px 12px', flexGrow: 1 }}>
              <Box>
                <Typography fontSize="11px" sx={{ borderBottom: 'dotted 2px', display: 'inline-block' }}>Reserved Items</Typography>
              </Box>
              <Typography fontSize="18px" fontWeight="bold" color="success.main">{reservedPairs}</Typography>
            </Paper>
            <Paper variant="outlined" sx={{ padding: '8px 12px', flexGrow: 1 }}>
              <Box>
                <Typography fontSize="11px" sx={{ borderBottom: 'dotted 2px', display: 'inline-block' }}>Unavailable Items</Typography>
              </Box>
              <Typography fontSize="18px" fontWeight="bold" color="orange">{unavailablePairs}</Typography>
            </Paper>
            <Paper variant="outlined" sx={{ padding: '8px 12px', flexGrow: 1 }}>
              <Box>
                <Typography fontSize="11px" sx={{ borderBottom: 'dotted 2px', display: 'inline-block' }}>Total</Typography>
              </Box>
              <Typography fontSize="18px" fontWeight="bold">{totalPairs}</Typography>
            </Paper>
          </Stack>
        </Grid>
      </Grid>
      <Stack spacing={2} mt={2}>
        <Stack direction={'row'} spacing={2}>

        </Stack>
        <Stack spacing={0.5}>
          <Divider />
          <Stack spacing={0.5} divider={<Divider orientation='horizontal' />}>
            {filteredLineItems && filteredLineItems.map(lineItem => <LineItem key={lineItem.orderName + lineItem.sku} lineItem={lineItem} openLogDialog={handleOpenLogDialog} addToSelection={handleAddToSelection} removeFromSelection={handleRemoveFromSelection} selected={selectedItems.find((item) => item.id === lineItem.id)} />)}
          </Stack>
          <Divider />
        </Stack>
      </Stack>
      {renderActionMenu}
      {renderSelectionMenu}
      <DownloadLabelsDialog lineItems={selectedItems.length ? selectedItems : filteredLineItems} selected={selectedItems.length} showDialog={downloadLabelsDialogVisible} closeDialog={() => handleCloseDownloadLabelsDialog(false)} />
      {logDialog && <LogDialog onClose={handleCloseLogDialog} lineItem={logDialog} />}
    </>
  );
}

export default BrazilOrders;