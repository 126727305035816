import React, { useState } from "react";
import Button from "@mui/material/Button";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { IconButton, styled } from "@mui/material";

const NoMaxWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 'none',
  },
});

const CopyToClipboardButton = ({ text, ...props }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(text);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };

  return (
    <NoMaxWidthTooltip title={copied ? "Copied!" : "Copy to Clipboard"}>
      <IconButton
        color={copied ? "success" : "primary"}
        onClick={handleCopy}
        {...props}
      >
        <ContentCopyIcon />
      </IconButton>
    </NoMaxWidthTooltip>
  );
};

export default CopyToClipboardButton;